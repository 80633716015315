@keyframes move {
    100% {
        transform: translate3d(0, 0, -1000px);
    }
}

.bubble-wrap-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform-style: preserve-3d;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.bubble-wrap {
    margin: 0 auto;
    width: 500px;
    height: 500px;
    transform-style: preserve-3d;
    transform-origin: center center;
    perspective: 600px;
}

.bubble {
    position: absolute;
    background: black;
    opacity: .7;
    border-radius: 50%;
    animation: move 4s linear infinite;

}

@for $i from 1 through 100 {
    .bubble:nth-child(#{$i}) {
        $size: random(30)+px;
        height: $size;
        width: $size;
        animation-delay: -$i * .2s;
        transform: translate3d((random(1000) * 1px), (random(1000) * 1px), (random(2000) * 1px));
        background: hsl(random(360), 70%, 50%);
    }

}