.ambking-container {
    margin-top: -14px;

    .main-slider-container {
        margin: 0 -12px;
        border-radius: 0px;
    }

    @media (min-width: $screen-md) {
        margin-top: 0;

        .main-slider-container {
            margin: 16px 0;
            border-radius: 16px;
        }
    }
}

.ambking-tab {
    height: 100%;

    .ant-tabs-nav-more {
        display: none;
    }

    .ant-tabs-content {
        height: 100%;
    }

    .ant-tabs-nav {
        position: sticky;
        top: 56px;
        background: rgba(#101010, 0.95);
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        // background: rgba(#101010, 0.7);
        // backdrop-filter: blur(20px);
        margin: 0 -12px;
        z-index: 998;
        will-change: background, border-bottom;
        transition: all 350ms ease;

        &::before {
            display: none;
        }

        &.active {
            background: rgba(#101010, 1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.075);
        }

        @media (min-width: $screen-md) {
            position: sticky;
            top: 64px;
            margin: 0;
            border-radius: 16px;
            border: 1px solid rgba($color-line, 0.2);
            box-shadow: rgba(0, 0, 0, 0.75) 0px 7px 24px 0px;
            // backdrop-filter: blur(20px);
            // background: rgba(0, 0, 0, 0.7);
            overflow: hidden;
        }

        @media (min-width: $screen-lg) {
            top: 84px
        }
    }

    .ant-tabs-nav-list {
        padding: 4px 8px;

        @media (min-width: $screen-md) {
            width: 100%;
            padding: 16px 8px;
        }
    }

    .ant-tabs-tab {
        font-size: 18px;
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 6px 10px;
        background: $gradient-light;
        border-radius: 12px;
        margin: 4px !important;
        min-width: 56px;
        will-change: color;

        &.ant-tabs-tab-active {
            background: $gradient-primary;
        }

        @media (min-width: $screen-md) {
            padding: 8px 10px;
            margin: 0 8px !important;
            font-size: 24px;
        }

        &:hover {
            color: $color-primary !important;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--color-text-primary-bg);
        z-index: 2;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .menu-tab {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            font-size: 20px;
        }

        .icon img {
            display: flex;
            width: 24px;
            margin-bottom: 4px;
        }

        @media (min-width: $screen-lg) {
            span {
                font-size: 22px;
            }

            .icon img {
                display: flex;
                width: 32px;
                margin-bottom: 4px;
            }
        }
    }

    .ambking-games-topbar {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

.ant-tabs-tab-active .menu-tab span {
    color: var(--color-text-primary-bg) !important;
    text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
}

.ambbet-provider-wrapper {
    margin-top: 12px;

    .ant-row {
        will-change: transform;
    }

    @media (min-width: $screen-lg) {
        padding: 16px 0;
        margin-top: 0;
    }
}

.ambbet-provider-card {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 0;
    border-radius: 12px;
    transition: all 250ms ease;
    cursor: pointer;
    will-change: box-shadow, transform;
    width: 100%;
    padding-top: 100%;
    position: relative;
    display: block;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $gradient-primary;
        background-size: 150%;
        background-position: 100% 200%;
        border-radius: 12px;
        will-change: background-position;
    }

    .ambbet-provider-img {
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(325deg, $color-grey-2 30%, lighten($color-grey-2, 10%) 100%);
        border-radius: 12px;
        will-change: background-position;

        img {
            width: 90%;
            margin-bottom: 8px;
            will-change: transform;
            transition: transform 250ms ease;
        }
    }

    &:hover {
        transform: scale(1.015) translateZ(0);
        box-shadow: hsla(var(--color-primary-hsl), 0.2) 0px 7px 24px 0px;

        &::before {
            background-position: 100% 100%;
        }

        .ambbet-provider-img {
            img {
                transform: scale(1.1) translateZ(0);
            }
        }
    }
}

a.ambking-provider-card {
    color: #fff;
}

.ambking-provider-card {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 0;
    transition: all 250ms ease;
    cursor: pointer;
    will-change: box-shadow, transform;
    width: 100%;
    padding-top: 137%;
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 8px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $gradient-primary;
        background-size: 150%;
        background-position: 100% 200%;
    }

    .ambking-provider-img-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .ambking-provider-img {
            position: absolute;
            top: 1px;
            left: 1px;
            bottom: 1px;
            right: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(325deg, $color-grey-2 30%, lighten($color-grey-2, 20%) 100%);
            text-align: center;
            padding-bottom: 20px;
            border-radius: 8px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .provider-name {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(#101010, 0.7);
            // backdrop-filter: blur(4px);
            padding: 2px 4px 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 0 0 8px 8px;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;

            @media (min-width: $screen-md) {
                padding: 6px 4px 3px;
            }
        }
    }

    .provider-name-title {
        font-size: 80%;
        font-style: bold;

        @media (min-width: $screen-xl) {
            font-size: 32px;
            font-style: bold;
        }
    }

    &:hover {
        transform: scale(1.015) translateZ(0);
        box-shadow: hsla(var(--color-primary-hsl), 0.2) 0px 7px 24px 0px;

        &::before {
            background-position: 100% 100%;
        }
    }
}

.ambbet-provider-has-games {
    display: block;

    &.hide-provider {
        display: none;
    }
}

.ambbet-games {
    display: none;

    &.show-games {
        display: block;
    }

    .ant-typography {
        text-transform: uppercase;
    }
}

.ambmet-game-card {
    position: relative;
    display: block;
    color: $color-white;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.25 ease;

    @media (min-width: $screen-md) {
        font-size: 20px;
    }

    &:hover {
        color: $color-primary;
    }
}

.ambmet-game-card-img {
    display: block;
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: 16px;
    box-shadow: rgba($color-black, 1) 0 12px 32px -16px;
    transition: all 0.25s ease-in-out;

    &:hover {
        border-color: $color-primary;
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 12px 32px -16px;
        transform: scale(1.025);
    }
}

.ant-tabs-content-holder {
    margin-top: 0;

    @media (min-width: $screen-lg) {
        margin: 0;
    }
}

.title-provider {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 !important;
    line-height: 1 !important;
    max-width: 150px;
    font-size: 26px !important;

    @media (min-width: $screen-md) {
        max-width: 100%;
        font-size: 28px !important;
    }
}

.search-games {
    flex: 1 auto;
    padding-left: 12px;
    max-width: 220px;

    @media (min-width: $screen-md) {
        max-width: 320px;
    }

    .ant-input-affix-wrapper {
        background: rgba(0, 0, 0, 0.6);
        border: 0;
        box-shadow: rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        border-radius: 12px;

        input {
            background: transparent;
            font-size: 22px;
        }

        .ant-input-prefix {
            margin-left: 2px;
            margin-right: 8px;
        }
    }
}

.amb-games-container {
    margin-top: 0;

    @media (min-width: $screen-md) {
        margin-top: 12px;
    }
}

.amb-games-filter {
    position: sticky;
    top: 126px;
    margin: 0 -12px 12px;
    padding: 6px 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.075);
    z-index: 998;
    overflow-y: auto;
    display: flex;

    // @if ((-webkit-backdrop-filter: blur(100px) saturate(75%)) or (backdrop-filter: blur(100px) saturate(75%))) {
    //     backdrop-filter: blur(100px) saturate(75%);
    // }

    // @else {
    //     background-color: rgba(0, 0, 0, 0.75);
    // }

    background-color: rgba(#202020, 0.9);

    .ant-btn {
        border: 0 !important;
        margin: 0 3px;
        box-shadow: inset 0 0 0 0.5px rgba(255, 255, 255, 0.15);
        font-size: 20px;
        padding: 0 12px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 250ms ease;

        &:hover {
            color: rgba(255, 255, 255, 0.85) !important;
        }

        &.active {
            color: $color-primary !important;
        }
    }

    @media (min-width: $screen-md) {
        position: sticky;
        top: 155px;
        margin: 0;
        padding: 8px 6px;
        margin-bottom: 16px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.075);

        .ant-btn {
            font-size: 22px;
            margin: 0 6px;

            &:hover {
                background: rgba(0, 0, 0, 0.25);
            }
        }
    }

    @media (min-width: $screen-lg) {
        top: 184px;
    }
}

.amb-games-sport {
    @media (max-width: $screen-md) {
        margin-top: 12px;
    }
}

.amb-games-content {
    display: flex;
    align-items: flex-start;

    .amb-games-provider-tab-container {
        position: sticky;
        top: 193px;
        width: 56px;
        min-width: 56px;
        height: calc(100dvh - 290px);
        padding: 6px;
        margin-right: 8px;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
        border-radius: 12px;
        overflow-y: auto;
        background-color: rgba(#202020, 0.9);

        @media (max-width: 390px) {
            width: 48px;
            min-width: 48px;
            padding: 4px;
        }

        @media (min-width: $screen-md) {
            top: 226px;
            height: calc(100dvh - 320px);
            margin-right: 12px;
        }

        @media (min-width: $screen-lg) {
            top: 260px;
            margin-right: 16px;
        }
    }

    .amb-games-provider-tab {
        padding-bottom: 160px;

        @media (min-width: $screen-md) {
            padding-bottom: 340px;
        }

        @media (min-width: $screen-lg) {
            padding-bottom: 0;
        }

        .amb-games-provider-tab-item {
            position: relative;
            width: 100%;
            padding-top: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 9px;
            margin-bottom: 4px;
            overflow: hidden;
            cursor: pointer;

            &.active {
                background: $gradient-primary;
                box-shadow: 0 0 10px $color-primary;

                span {
                    color: $color-black !important;
                }
            }

            .provider-name-title {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(-325deg, rgba(255, 255, 255, 0.15) 30%, rgba(255, 255, 255, 0) 100%);

                span {
                    text-align: center;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    color: $text-color-secondary;
                }
            }

            img {
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                width: calc(100% - 4px);
                border-radius: 10px;
                display: flex;
            }
        }
    }
}

.lazy-load-image-background {
    will-change: filter !important;
}

.ambking-new-tab {
    height: 100%;

    .ant-tabs-nav-more {
        display: none;
    }

    .ant-tabs-content {
        height: 100%;
    }

    .ant-tabs-nav {
        position: sticky;
        top: 55px;
        background: #101010;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        margin: 0 -12px;
        z-index: 998;
        will-change: background, border-bottom;
        transition: all 350ms ease;

        &::before {
            display: none;
        }

        &.betflix-list {
            display: flex;

            .ant-tabs-nav-list {
                width: 100%;
                height: 60px;
                overflow: visible;
                box-sizing: border-box;

                .ant-tabs-tab {
                    height: 100%;
                }

                @media (min-width: $screen-md) {
                    height: 76px;
                    padding: 8px;
                }
            }
        }

        @media (min-width: $screen-md) {
            position: sticky;
            top: 64px;
            margin: 0;
            border-radius: 16px;
            background: rgba(0, 0, 0, 0.95) !important;
            border: 1px solid rgba($color-line, 0.2);
            box-shadow: rgba(0, 0, 0, 0.75) 0px 7px 24px 0px;
            // backdrop-filter: blur(20px);
            overflow: hidden;
        }

        @media (min-width: $screen-lg) {
            top: 84px
        }
    }

    .ant-tabs-nav-list {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        height: 66px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .ant-tabs-tab {
        font-size: 18px;
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4px 6px;
        background: $gradient-light;
        border-radius: 12px;
        box-sizing: border-box;
        margin: 4px !important;
        min-width: 56px;
        will-change: color;
        cursor: pointer;

        &.ant-tabs-tab-active {
            background: $gradient-primary;
        }

        @media (min-width: $screen-md) {
            flex: 1;
            padding: 6px 12px;
            margin: 0 6px !important;
            font-size: 24px;
        }

        &:hover {
            color: $color-primary !important;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--color-text-primary-bg);
        z-index: 2;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .menu-tab {
        display: flex;
        flex-direction: column;
        align-items: center;


        span {
            color: #fff;
            font-size: 20px;
            white-space: nowrap;
        }

        .icon img {
            display: flex;
            width: 28px;
        }

        @media (min-width: $screen-lg) {
            span {
                font-size: 22px;
            }

            .icon img {
                display: flex;
                width: 32px;
                margin-bottom: 4px;
            }
        }
    }

    .ambking-games-topbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-white;
    }
}

.ambking-grid-row {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 12px;

    @media (min-width: $screen-md) {
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
    }

    @media (min-width: $screen-lg) {
        grid-template-columns: repeat(6, 1fr);
        gap: 16px;
        margin-top: 16px;
    }

    .ambking-grid-col {
        position: relative;

        .ambking-grid-col-fav {
            position: absolute;
            top: -10px;
            right: -10px;
            z-index: 2;
            padding: 4px;
            cursor: pointer;
            will-change: transform;
            transition: transform 250ms ease;

            img {
                width: 22px;
                display: block;
                filter: grayscale(100%);
            }

            &:hover {
                transform: scale(1.05);
            }

            &.active {
                img {
                    filter: grayscale(0);
                }
            }

            @media (min-width: $screen-md) {
                top: -8px;
                right: -8px;

                img {
                    width: 24px
                }
            }
        }

        .ambking-grid-col-rtp {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: linear-gradient(300deg, #ecc440, #fffa8a, #ddac17, #ffff95);
            background-size: 200% 200%;
            animation: gradient-animation 2s ease infinite;
            color: #000;
            text-shadow: 1px 2px 2px #fff;
            padding: 2px 4px;
            font-size: 18px;
            font-weight: bold;
            line-height: 14px;
            border-radius: 0 0 6px 0;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
                0 4px 12px #ecc440,
                inset -0.5px -0.5px 0 0.1px rgba(255, 255, 255, 0.5);

            @media (max-width: 390px) {
                font-size: 16px;
                line-height: 12px;
                padding: 0 4px;

                span {
                    font-size: 12px;
                }
            }

            @media (min-width: $screen-md) {
                font-size: 18px;
                line-height: 16px;
            }
        }

    }
}

.game-promo-filter {
    position: relative;
    background: $gradient-dark;
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
    border-radius: 12px;
    margin-left: 12px;
    padding: 0 12px;
    color: #fff;
    font-size: 18px;
    line-height: 0.75;
    text-align: center;
    transition: all 250ms ease;
    cursor: pointer;

    .game-promo-checked {
        position: absolute;
        top: -4px;
        right: -4px;
        background: $color-success;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        opacity: 0;
        transition: opacity 250ms ease;

        .material-symbols-rounded {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            font-size: 16px;
            color: #fff !important;
        }
    }

    &.checked {
        color: $color-success;
        box-shadow: inset 0 0 0 1px $color-success;

        .game-promo-checked {
            opacity: 1;
        }
    }

    @media (min-width: $screen-lg) {
        height: 48px;
        border-radius: 16px;
        line-height: 1;

        &:hover {
            color: $color-success;
        }
    }
}

.ant-alert-message {
    margin-bottom: 5px
}


.ambking-grid-row-bf {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 12px;

    @media (min-width: $screen-md) {
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
    }

    @media (min-width: $screen-lg) {
        grid-template-columns: repeat(6, 1fr);
        gap: 16px;
        margin-top: 16px;
    }

    .ambking-grid-col {
        position: relative;

        .ambking-grid-col-fav {
            position: absolute;
            top: -10px;
            right: -10px;
            z-index: 2;
            padding: 4px;
            cursor: pointer;
            will-change: transform;
            transition: transform 250ms ease;

            img {
                width: 22px;
                display: block;
                filter: grayscale(100%);
            }

            &:hover {
                transform: scale(1.05);
            }

            &.active {
                img {
                    filter: grayscale(0);
                }
            }

            @media (min-width: $screen-md) {
                top: -8px;
                right: -8px;

                img {
                    width: 24px
                }
            }
        }

        .ambking-grid-col-rtp {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: linear-gradient(300deg, #ecc440, #fffa8a, #ddac17, #ffff95);
            background-size: 200% 200%;
            animation: gradient-animation 2s ease infinite;
            color: #000;
            text-shadow: 1px 2px 2px #fff;
            padding: 2px 4px;
            font-size: 18px;
            font-weight: bold;
            line-height: 14px;
            border-radius: 0 0 6px 0;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
                0 4px 12px #ecc440,
                inset -0.5px -0.5px 0 0.1px rgba(255, 255, 255, 0.5);

            @media (max-width: 390px) {
                font-size: 16px;
                line-height: 12px;
                padding: 0 4px;

                span {
                    font-size: 12px;
                }
            }

            @media (min-width: $screen-md) {
                font-size: 18px;
                line-height: 16px;
            }
        }

    }


    .game-card-img-container {
        position: relative;
        width: 100%;
        padding-bottom: 137%;
        // background-color: rgba(#202020, 0.7);
        will-change: transform;
        transform: translateZ(0);


        .game-card-logo-url:first-child {
            z-index: 2 !important;
            /* วาง PNG ไว้ด้านบน */
        }

        .game-card-logo-url:last-child {
            z-index: 1 !important;
            height: 100% !important;
            /* วาง GIF ไว้ด้านล่าง */
        }
    }
}

.betflix-tab {
    .amb-games-filter {
        top: 116px;
    }

    .amb-games-content .amb-games-provider-tab-container {
        top: 178px
    }
}


.ambking-grid-row-sport {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 12px;

    @media (min-width: $screen-md) {
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
    }

    @media (min-width: $screen-lg) {
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        margin-top: 16px;
    }

    .ambking-grid-col {
        position: relative;

        .ambking-grid-col-fav {
            position: absolute;
            top: -10px;
            right: -10px;
            z-index: 2;
            padding: 4px;
            cursor: pointer;
            will-change: transform;
            transition: transform 250ms ease;

            img {
                width: 22px;
                display: block;
                filter: grayscale(100%);
            }

            &:hover {
                transform: scale(1.05);
            }

            &.active {
                img {
                    filter: grayscale(0);
                }
            }

            @media (min-width: $screen-md) {
                top: -8px;
                right: -8px;

                img {
                    width: 24px
                }
            }
        }

        .ambking-grid-col-rtp {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: linear-gradient(300deg, #ecc440, #fffa8a, #ddac17, #ffff95);
            background-size: 200% 200%;
            animation: gradient-animation 2s ease infinite;
            color: #000;
            text-shadow: 1px 2px 2px #fff;
            padding: 2px 4px;
            font-size: 18px;
            font-weight: bold;
            line-height: 14px;
            border-radius: 0 0 6px 0;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
                0 4px 12px #ecc440,
                inset -0.5px -0.5px 0 0.1px rgba(255, 255, 255, 0.5);

            @media (max-width: 390px) {
                font-size: 16px;
                line-height: 12px;
                padding: 0 4px;

                span {
                    font-size: 12px;
                }
            }

            @media (min-width: $screen-md) {
                font-size: 18px;
                line-height: 16px;
            }
        }



    }
}

.game-card-sport {
    position: relative;
    display: flex;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    // border: 1px solid rgba(#fff, 0.075);
    cursor: pointer;

    .game-card-img-container {
        position: relative;
        width: 100%;
        padding-bottom: 25%;
        // background-color: rgba(#202020, 0.7);
        will-change: transform;
        transform: translateZ(0);
    }


    .game-card-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100%;
        object-fit: cover;
    }

    .game-card-name {
        position: absolute;
        bottom: 0;
        left: -1px;
        right: -1px;
        background-color: rgba(#101010, 0.7);
        // backdrop-filter: blur(4px);
        padding: 1px 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-white;
        font-size: 16px;
        text-align: center;
        line-height: 1;
        will-change: transform;
        transform: translateZ(0);

        @media (min-width: $screen-md) {
            padding: 2px 4px 1px;
        }
    }
}