.ant-form-item-explain {
    margin-top: 4px;

    div {
        margin-bottom: 6px;
        font-size: 22px !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
}

// Error
.ant-form-item-explain-error {
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.8);
}

.ant-form-item-has-error .ant-input {
    border-color: #f5222d;
}

.ant-form-item .ant-form-item-extra {
    min-height: 0;
    font-size: 22px;
    margin-top: 6px;
}