body {
    position: relative;
    font-weight: normal;
    font-size: 24px;
    font-family: DB HelvethaicaX, Arial, sans-serif;
    padding: 0;
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

@media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
        display: none;
    }
}

.achieve-container {
    h1,
    h2 {
        color: $color-white;
        margin: 12px 0 8px;
    }

    p {
        color: rgba(255, 255, 255, 0.9);
        margin: 0 0 16px;

        b {
            color: $color-white;
        }
    }
    img {
        width: 100%;
    }
}

.hidden {
    display: none !important;
}

.avatar-logo {
    background: transparent;

    img {
        object-fit: contain;
    }

    .ant-avatar-string {
        color: $color-primary;
        font-size: 40px;
        font-weight: bold;
    }
}

.avatar-logo-login {
    width: 150px;
    height: 100px;
}

.avatar-logo-topbar {
    width: 150px;
    height: 100px;
    border-radius: 0 !important;

    img {
        object-position: left;
    }
}

.avatar-logo-sidebar {
    width: 150px !important;
    max-height: 80px;
    border-radius: 0 !important;
}

.ambpay-qrcode {
    text-align: center;
    margin-bottom: 16px;

    img {
        width: 150px;
        border: 8px solid #eee;
        border-radius: 8px;
    }
}

.heading {
    color: $color-primary;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
}

.primary-color {
    color: $color-white;
}

.test-slider {
    position: relative;
    width: 100%;
    // min-height: 400px;
    display: flex;
    align-items: center;

    img {
        border-radius: 16px;
        animation: fadeIn 0.3s ease-in-out;
    }
}

.bg-main {
    position: relative;

    img {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        will-change: transform;
        transform: translateZ(0);
        overflow: hidden;
        z-index: -1;
        object-fit: cover;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    padding: 16px;
    height: 100dvh;
    box-sizing: border-box;
    width: 280px;
    display: none;

    .sidebar-inner {
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        // backdrop-filter: blur(50px);
        // -webkit-backdrop-filter: blur(50px);
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
        border-radius: 16px;
        overflow: hidden;
    }

    @media (min-width: $screen-lg) {
        display: block;
    }
}

.content {
    width: auto;
    padding: 70px 12px 120px;
    box-sizing: border-box;

    @media (min-width: $screen-lg) {
        padding: 0 0 16px 280px;
    }
}

.chat-button {
    position: absolute;
    top: 0;
    right: 0;

    .ant-btn-dark {
        font-size: 20px;
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00a344;
        box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        border-radius: 0 32px 0 12px;

        i {
            padding-right: 10px;
            color: #fff !important;
        }

        span {
            margin-top: 3px
        }

        &:hover {
            transform: none;
            background: #00903c;
            box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        }
    }
}

.skeleton-container {
    position: relative;
    width: 100%;
    padding-top: 35.09%;
    background: linear-gradient(90deg, #373737 25%, #343434 50%, #373737 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.skeleton-popup-image {
    background: #202020;
    border-radius: 16px;

    .ant-skeleton {
        width: 100%;

        .ant-skeleton-image {
            height: 250px;

            svg {
                display: none;
            }
        }
    }
}

.uid-line {
    padding-top: 6px;
    margin-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.075);
}

img {
    -webkit-touch-callout: none !important;
}

.button-footer-container {

    .ant-float-btn,
    .ant-float-btn-group {
        inset-inline-end: 12px;
        transition: all 0.25s ease;
        z-index: 9999;

        img {
            display: block;
            margin: 0 auto;
            width: 30px;
        }
    }

    .ant-float-btn-group {
        .ant-float-btn-group-wrap {
            margin-bottom: 8px;
        }
    }

    .ant-float-btn-group-circle .ant-float-btn-circle:not(:last-child) {
        margin-bottom: 8px;
    }

    @media (max-width: $screen-md) {
        .ant-float-btn-group {
            width: 48px;
            min-height: 48px;
            inset-block-end: 136px;
        }

        .ant-float-btn-group-circle .ant-float-btn-circle:not(:last-child) {
            .ant-float-btn-body {
                width: 48px;
                height: 48px;
            }
        }

        .ant-float-btn-circle {
            width: 48px;
            height: 48px;

            img {
                width: 24px;
            }

            .ant-float-btn-icon {
                font-size: 22px !important;
            }
        }
    }
}

.Toastify__toast-container,
.ant-image-preview-mask,
.ant-image-preview-wrap,
.ant-image-preview-operations-wrapper,
.ant-message {
    z-index: 99999 !important;
}

.ant-message {
    z-index: 10010 !important;
}

// .ant-typography,
// h4.ant-typography,
// h5.ant-typography,
// .ant-tag,
// .anticon-close,
// .anticon-search,
// .ant-form-item .ant-form-item-label>label,
// .ant-checkbox-wrapper,
// .ant-input,
// .ant-select,
// .ant-select-selection-placeholder,
// .ant-select-item-option-content,
// .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
// .ant-select-single .ant-select-selector,
// .ant-modal .ant-modal-title,
// .ant-input-number .ant-input-number-input,
// .ant-input-number-affix-wrapper,
// .ant-statistic .ant-statistic-content,
// .ant-radio-button-wrapper,
// .ant-radio-wrapper,
// .ant-empty .ant-empty-description,
// .ant-btn-default {
//     color: rgba(255, 255, 255, 0.98);
// }

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 255, 0.1);
}

.ant-input:focus,
.ant-input:focus-within,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus-within,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus-within {
    box-shadow: none;
}



.terms-and-conditions-button {
    position: absolute;
    top: 0;
    right: 0;

    .ant-btn-dark {
        font-size: 20px;
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gradient-primary;
        box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        border-radius: 0 32px 0 12px;

        i {
            padding-right: 10px;
            color: #fff !important;
        }

        span {
            margin-top: 3px
        }

        &:hover {
            transform: none;
            background: $gradient-primary-shadow;
            box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        }
    }
}

.modal-terms {
    .ant-modal-body {
        max-height: 450px;
        overflow-y: auto;
        padding-top: 12px;
    }

    .ant-modal-header {
        background: #202020 !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.075);
        border-radius: 0;
        margin-bottom: 0;
        padding-bottom: 8px;
    }
}

.iframe-game {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100dvh - 48px);
    border: none;

    @media (min-width: $screen-md) {
        top: 56px;
        height: calc(100dvh - 56px);
    }
}

.termandcondition-form-item {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;

    .ant-checkbox+span {
        padding-inline-end: 0px !important;
    }
}

.termandcondition {
    cursor: pointer;
    color: $color-white;
    text-decoration: underline;
}

.test-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    width: 100%;
    height: 100%;
}

.ant-checkbox .ant-checkbox-inner:after {
    border-color: var(--color-text-primary-bg) !important;
}

.custom-turnstile {
    display: flex;

    iframe {
        width: 240px !important;
        height: 52px !important;
    }
}

.register-main-slider {
    .main-slider-container {
        img {
            max-height: 150px;
        }
    }
}

.p2p {
    .deposit-method {
        padding: 16px 16px 16px 96px !important;
    }
}

.peer2pay-body {
    @media (min-width: $screen-md) {
        max-width: 320px;
        display: block;
        margin: 0 auto;
    }

    .peer2pay-top {
        background: #fff;
        border-radius: 12px 12px 0 0;
        padding: 16px;

        .peer2pay-top-img {
            margin: 0 auto;
            display: block;
        }

        .ant-divider {
            border-color: rgba(0, 0, 0, 0.1);

            &.ant-divider-horizontal.ant-divider-with-text {
                color: rgba(0, 0, 0, 0.88);
                margin: 0 0 8px;

                .ant-divider-inner-text {
                    font-size: 22px;
                }
            }
        }

        .p2p-mobile {
            cursor: pointer;
        }

        .p2p-desktop {
            display: none;
        }

        @media (min-width: $screen-md) {
            .p2p-desktop {
                display: block;
                cursor: pointer;
            }

            .p2p-mobile {
                display: none;
            }
        }
    }

    .peer2pay-bottom {
        background: #172b68;
        border-radius: 0 0 12px 12px;
        padding: 16px;

        .peer2pay-info {
            display: flex;
            justify-content: space-between;
            font-size: 22px;

            &:not(:last-child) {
                margin-bottom: 6px;
            }

            .peer2pay-info-amount {
                font-weight: bold;
                color: $color-success;
            }
        }
    }
}

.ant-input-p2p .ant-input-number-input {
    text-align: right !important;
    padding-right: 10px !important;
}

.p2p-modal {
    .ant-modal-title {
        text-align: center;
    }
}

.p2p-withdraw-description {
    &.ant-descriptions {
        .ant-descriptions-header {
            margin-bottom: 0 !important;

            .ant-descriptions-title {
                font-size: 24px;
            }
        }

        .ant-descriptions-item {
            padding-bottom: 4px;

            .ant-descriptions-item-content {
                font-size: 22px;
                display: inline-block;
            }
        }
    }
}

.ant-ribbon-secondary {
    top: -8px;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.2), -2px 2px 6px rgba($color-black, 0.2);
    line-height: 24px;
    background: $gradient-secondary;
    background-size: 200% 200%;
    animation: gradient-animation 2s ease infinite;

    .ant-ribbon-text {
        font-size: 20px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    }

    .ant-ribbon-corner {
        color: $color-secondary;
    }
}


.ant-ribbon-promotion {
    top: -8px;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.2), -2px 2px 6px rgba($color-black, 0.2);
    line-height: 30px;
    background: $gradient-red;
    background-size: 200% 200%;
    animation: gradient-animation 2s ease infinite;

    .ant-ribbon-text {
        font-size: 20px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    }

    .ant-ribbon-corner {
        color: $color-danger;
    }
}

.notice-wrapper {
    display: flex;
    background: $gradient-primary;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    margin-bottom: 10px;

    .notice-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;

        img {
            width: 15px;
            display: block;
            filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.4));
        }
    }

    .notice-text {
        position: relative;
        display: flex;
        align-items: center;
        flex: auto;
        color: var(--color-text-primary-bg);
        text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
        font-size: 18px;

        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: -1px;
            bottom: 1px;
            width: 32px;
            background: linear-gradient(90deg, var(--color-primary-start) 0%, transparent 90%);
            z-index: 2;
        }

        .rfm-marquee-container {
            .rfm-child {
                // font-size: 18px !important;
                margin: 0 12px;
            }
        }
    }

    @media (min-width: $screen-md) {
        margin-bottom: 16px;

        .notice-icon {
            padding: 8px 12px;

            img {
                width: 24px;
            }
        }

        .notice-text {
            font-size: 24px;
        }

    }

    @media (max-width: 767px) {
        &.notice-full {
            margin: 0 -12px;
            border-radius: 0;
            box-shadow: none;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
    }
}

.promotion-method-wrapper {
    .ant-ribbon-wrapper {
        height: 100%;
    }

    &.hidden-ribbon {
        .ant-ribbon {
            display: none;
        }
    }
}


.payments-main {
    .ant-card {
        border-radius: 0 0 12px 12px;
        background-color: #fff !important;
        border: 0;
    }

    .ant-card-cover {
        padding: 10px;
    }

    .ant-card-meta-title {
        font-size: 28px;
        margin-bottom: 5px !important;
        text-align: center;
    }

    .ant-card-meta-description {
        font-size: 16px;
        text-align: center;
    }

    .ant-card .ant-card-body {
        padding: 0 16px 16px;
        // padding-left: 16px;
    }

    .ant-card-head {
        padding: 0px;
    }

    .ant-card-body {
        border-radius: 0 0 12px 12px;
    }
}

.ant-statistic-title {
    margin-bottom: 0 !important;
}
