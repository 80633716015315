.offline-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    padding: 24px;
    // backdrop-filter: blur(10px);

    .offline-notice {
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 16px 24px;
        background: rgba($color-white, 0.85);
        border-radius: $border-radius-card;

        @media (min-width: $screen-md) {
            max-width: 400px;
        }

        i {
            margin-right: 16px;
            color: #e5003c;
        }

        .ant-typography {
            color: $color-black;
        }
    }
}