.ambbet-topbar-skeleton {
    position: fixed;
    top: 56px;
    width: 100%;
    height: 66px;
    background: #101010;
    border-bottom: 1px solid rgba(255, 255, 255, 0.075);
    overflow: hidden;
    margin: 0 -12px;
    z-index: 998;

    .amb-button-skeleton {
        width: 100%;
        overflow-y: auto;
        display: flex;
    }

    .ant-skeleton-button {
        margin: 0 4px 4px;
        min-width: 69px !important;
        width: 69px !important;
        height: 60px !important;
        border-radius: 12px !important;
    }

    @media (min-width: $screen-md) {
        position: relative;
        top: 64px;
        margin: 0;
        border-radius: 16px;
        border: 1px solid rgba($color-line, 0.2);
        box-shadow: rgba(0, 0, 0, 0.75) 0px 7px 24px 0px;
        // backdrop-filter: blur(20px);
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;

        .amb-button-skeleton {
            width: 100%;
            overflow-y: visible;
            padding: 0 12px;
        }

        .ant-skeleton {
            flex: auto;
            padding: 0 8px;
        }

        .ant-skeleton-button {
            margin: 0;
            min-width: 0 !important;
            width: 100% !important;
            height: 48px !important;
        }
    }

    @media (min-width: $screen-lg) {
        top: 84px;
        height: 106px;

        .ant-skeleton-button {
            height: 74px !important;
        }
    }
}

.ambbet-provider-skeleton {
    padding-top: 64px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 8px;

    @media (min-width: $screen-md) {
        grid-template-columns: auto auto auto auto;
        grid-gap: 12px;
    }

    @media (min-width: $screen-lg) {
        grid-template-columns: auto auto auto auto auto auto;
        grid-gap: 16px;
    }

    .ant-skeleton {
        position: relative;
        width: 100%;
        padding-top: 100%;
    }

    .ant-skeleton-button {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        min-width: 0 !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 12px !important;
        // backdrop-filter: blur(20px) !important;
    }

    @media (min-width: $screen-md) {
        padding-top: 32px;
    }
}

.deposit-skeleton {
    .ant-skeleton.ant-skeleton-element {
        display: block;
        margin-bottom: 16px;
    }

    .ant-skeleton-button {
        width: 100% !important;
        height: 72px !important;
        // backdrop-filter: blur(20px) saturate(75%) !important;
        border-radius: 24px !important;
    }
}


.ambbet-provider-skeleton-loading {
    width: 100%;
    padding-top: 10px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 8px;

    @media (min-width: $screen-md) {
        grid-template-columns: auto auto auto auto;
        grid-gap: 12px;
    }

    @media (min-width: $screen-lg) {
        margin-top: 43px;
        grid-template-columns: auto auto auto auto auto auto;
        grid-gap: 16px;
    }

    .ant-skeleton {
        position: relative;
        width: 100%;
        padding-top: 100%;
    }

    .ant-skeleton-button {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        min-width: 0 !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 12px !important;
        // backdrop-filter: blur(20px) !important;
    }

    @media (min-width: $screen-md) {
        padding-top: 20px;
    }
}

/* CSS Skeleton Styles */
.skeleton-container {
    position: relative;
    overflow: hidden;
}

.skeleton-body {
    position: relative;
    width: 100%;
    padding-bottom: 137%;
    overflow: hidden;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
}

.skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent 0, rgba(#e0e0e0, 0.1) 50%, transparent 100%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    transform: translateX(-100%);
}

/* CSS Animation Keyframes */
@keyframes shimmer {
    to {
        transform: translateX(100%);
    }
}