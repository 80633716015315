.menu-sidebar {
    position: absolute;
    top: 125px;
    left: 17px;
    right: 17px;
    bottom: 16px;
    margin: 0;
    padding: 0 28px 16px;
    overflow-x: hidden;
    overflow-y: auto;

    li {
        list-style: none;

        a {
            position: relative;
            display: flex;
            padding: 12px 0;
            color: $color-white;
            font-size: 24px;

            img {
                width: 24px;
                margin-right: 20px;
                transition: transform 0.25s ease;
                z-index: 1;
            }

            span {
                text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
                transition: transform 0.25s ease;
                z-index: 1;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -16px;
                bottom: 0;
                right: -16px;
                border-radius: 12px;
                box-shadow: inset 0 0 0 1px rgba($color-white, 0.075);
                background: $gradient-primary;
                opacity: 0;
                transition: all 0.25s ease;
            }

            &:hover {
                &:not(.active-menu) {
                    color: var(--color-text-primary-bg);

                    img {
                        transform: scale(1.1);
                    }

                    span {
                        transform: translateX(6px);
                    }

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        a.active-menu {
            span {
                color: var(--color-text-primary-bg);
            }

            &:before {
                opacity: 1;
                background: $gradient-primary;
            }
        }
    }
}

.bottombar-mobile {
    position: fixed;
    right: 0;
    bottom: -1px;
    left: 0;
    border-radius: $border-radius-card $border-radius-card 0 0;
    z-index: 1000;
    display: block;
    // background: rgba(#101010, 0.95);
    background: $gradient-primary-bottom;
    // background: rgba(0, 0, 0, 0.7);
    // backdrop-filter: blur(20px);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);

    &.bottombar-dark {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: -1;
        }
    }

    @media screen and (min-width: $screen-lg) {
        display: none;
    }

    .nav-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-around;
        margin: 0;
        padding: 0;

        .nav-item {
            -ms-flex: 1;
            flex: 1;
            text-align: center;
            list-style-type: none;
            -webkit-box-flex: 1;

            .nav-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding: 8px 0 6px;
                color: var(--color-text-primary-bg);
                text-decoration: none;

                img {
                    margin-bottom: 6px;
                    width: 26px;
                    max-height: 26px;
                    filter: drop-shadow(1px 2px 4px var(--color-text-shadow-primary-bg));
                }

                span {
                    display: block;
                    font-size: 18px;
                    line-height: 1;
                    text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
                    opacity: 0.88;
                }
            }

            .nav-link.active {
                span {
                    font-weight: bold;
                    text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg),
                        1px 2px 4px var(--color-text-shadow-primary-bg);
                    opacity: 1;
                }
            }
        }

        .nav-item.middle-item {
            display: flex;

            span {
                display: block;
                margin-top: 5px;
                color: var(--color-text-primary-bg);
                font-size: 18px;
            }

            .nav-link {
                flex: auto;
                opacity: 1;
            }

            .icon-button-container {
                position: absolute;
                bottom: 50%;
                z-index: 1;
                width: 60px;
                height: 60px;
                padding: 0;
                color: $color-grey-1;
                transition: -webkit-transform 0.15s ease-out;
                transition: transform 0.15s ease-out;

                .icon-center {
                    position: absolute;
                    top: -2px;
                    left: -5px;
                    z-index: 1;
                    width: 72px;
                    max-height: 100%;
                    margin: 12px auto auto;
                    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
                }

                &.dance {
                    .icon-center {
                        animation: dance 2s infinite;
                    }
                }

                &.zoom {
                    .icon-center {
                        animation: zoomIn 2s infinite;
                    }
                }
            }

            .icon-button {
                z-index: 1;
                width: 60px;
                height: 60px;
                border-radius: $border-radius-card;
                overflow: hidden;
                animation: pulse 2s infinite;
            }

            .icon-container {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: $border-radius-card;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -2;
                    width: 120%;
                    height: 120%;
                    background-color: transparent;
                    background-image: linear-gradient(90deg,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 0) 0%,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 1) 50%,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 0) 100%);
                    background-repeat: no-repeat;
                    transform-origin: center;
                    -webkit-animation: rotate 2s linear infinite;
                    animation: rotate 2s linear infinite;
                    content: '';
                }

                &::after {
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    left: 1px;
                    background: $gradient-secondary;
                    border-radius: $border-radius-card;
                    content: '';
                }
            }
        }
    }
}

.bottombar-radius-mobile {
    position: fixed;
    right: 0;
    bottom: -1px;
    left: 0;
    z-index: 1000;

    @media screen and (min-width: $screen-lg) {
        display: none;
    }

    .bg-wrapper {
        width: 100%;
        height: 64px;
        display: flex;
    }

    .bg-gradient-wrapper {
        flex: 1;
        background: $gradient-primary-bottom;

        &:first-child {
            border-radius: 16px 0 0 0;
            margin-right: -1px;
        }

        &:last-child {
            border-radius: 0 16px 0 0;
            margin-left: -1px;
        }
    }

    &.bottombar-radius-dark {
        .bg-gradient-wrapper {
            &:first-child {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    border-radius: 16px 0 0 0;
                    z-index: 0;
                }
            }

            &:last-child {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    border-radius: 0 16px 0 0;
                    z-index: 0;
                }
            }
        }

        .bg-gradient-svg {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.7);
                z-index: 0;
            }
        }
    }
}

.navigate-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 0;
    margin: 0 auto;

    .navigate-container {
        display: flex;
        flex: 1;
        padding: 0 8px;

        .navigate-item {
            position: relative;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            color: var(--color-text-primary-bg);
            text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);

            img {
                margin-bottom: 6px;
                width: 26px;
                max-height: 26px;
                object-fit: contain;
                filter: drop-shadow(1px 2px 4px var(--color-text-shadow-primary-bg));
            }

            span {
                display: block;
                font-size: 18px;
                padding-bottom: 6px;
                line-height: 1;
                text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
                opacity: 0.88;
            }

            &.active {
                span {
                    font-weight: bold;
                    text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg),
                        1px 2px 4px var(--color-text-shadow-primary-bg);
                    opacity: 1;
                }
            }

            .navigate-main-icon {
                position: absolute;
                top: -38px;
                left: 50%;
                width: 64px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $gradient-secondary;
                border-radius: 50%;
                transform: translate(-50%);
                color: var(--color-text-secondary-bg);
                box-shadow: 0 0 10px $color-secondary, inset 0 0 2px #00000080;
                text-shadow: 1px 2px 4px var(--color-text-shadow-secondary-bg);
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -2;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    background-image: linear-gradient(90deg,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 0) 0%,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 1) 50%,
                            hsla(var(--color-secondary-h),
                                var(--color-secondary-s),
                                calc(var(--color-secondary-l) + 40%), 0) 100%);
                    background-repeat: no-repeat;
                    transform-origin: center;
                    -webkit-animation: rotate 2s linear infinite;
                    animation: rotate 2s linear infinite;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    left: 1px;
                    background: $gradient-secondary;
                    border-radius: 50%;
                }

                img {
                    position: relative;
                    width: 52px;
                    max-height: 52px;
                    margin-top: 4px;
                    z-index: 2;
                }

                &.dance {
                    img {
                        animation: dance 2s infinite;
                    }
                }

                &.zoom {
                    img {
                        animation: zoomIn 2s infinite;
                    }
                }
            }
        }
    }
}

.menu-mobile-wrapper {
    padding: 0 20px;
    display: grid;
    gap: 12px 32px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 12px;

    @media (max-width: 390px) {
        gap: 12px 24px;
        padding: 0 12px;
    }

    @media (min-width: $screen-md) {
        grid-template-columns: repeat(8, 1fr);
    }

    @media (min-width: $screen-lg) {
        display: none;
    }
}

.menu-mobile {
    display: block;
    color: $color-white;
    font-size: 20px;
    text-align: center;
    white-space: nowrap;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 1);

    @media (max-width: 390px) {
        font-size: 18px;
    }
}

.menu-mobile-icon {
    position: relative;
    width: 100%;
    margin-bottom: 6px;
    padding-top: 100%;
    border-radius: 16px;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $gradient-primary-shadow;
        box-shadow: inset 0 0 0 1px rgba($color-white, 0.2);
        border-radius: 16px;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        transform: translate(-50%, -50%);
        filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.4));
    }
}

.account-menu {
    background: $gradient-light;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border-radius: $border-radius-card;
    display: flex;
    align-items: center;
    padding: 16px;
    transition: all 0.25s ease;

    .anticon {
        margin-left: auto;
        color: $color-white;
        font-size: 20px;
        transition: all 0.25s ease;
    }

    .topbar-name {
        display: flex;
        align-items: center;
        transition: all 0.25s ease;
    }

    .account-menu-name {
        display: flex;
        align-items: center;
        color: $color-white;
        transition: all 0.25s ease;

        svg {
            margin-right: 16px
        }
    }

    svg {
        width: 24px;
        height: 24px;
    }

    // &:hover {
    //     border-color: hsla(var(--color-primary-hsl), 0.1);

    //     .anticon,
    //     .topbar-name,
    //     .account-menu-name {
    //         color: $color-primary;
    //     }
    // }
}

.game-menu {
    position: sticky;
    top: 80px;
    padding: 16px 8px;
    background: $gradient-dark;
    border: 1px solid rgba($color-line, 0.2);
    border-radius: 16px;
}

.game-menu-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 4px;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    border-radius: 12px;
    cursor: pointer;
    transition: color .25s ease;
    will-change: color;

    @media (min-width: $screen-md) {
        padding: 12px 4px;
        font-size: 22px;
    }

    img {
        width: 28px;
        margin-bottom: 4px;
        filter: grayscale(50%);
        transition: all .25s ease;
        will-change: transform, filter;

        @media (min-width: $screen-md) {
            width: 48px;
        }
    }

    &.active {
        color: $color-black;
        font-weight: 700;
        background: $gradient-primary;
        box-shadow: hsla(var(--color-primary-hsl), 20%) 0 10px 20px -20px;
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover {
        &:not(.active) {
            color: $color-primary;

            img {
                transform: scale(1.1);
                filter: grayscale(0%);
            }
        }
    }
}