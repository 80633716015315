.rolling-balance {
    background: linear-gradient(121.12deg, #262626 19.2%, #1b1b1b 51.08%, #1b1b1b 51.08%);
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border-radius: 16px;
    color: #fff;
    text-align: center;
    padding: 12px;
    margin-bottom: 16px;
    line-height: 1;
}

.rolling-text-extra {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-top: 6px;

    .rolling-text-extra-span {
        color: $color-primary !important;
        font-weight: bold;
    }
}

.rolling-text-info {
    display: flex;
    background: $gradient-dark;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border-radius: 16px;
    text-align: center;
    color: #fff;
    line-height: 0.8;
    padding: 12px;
    font-size: 20px;
    margin-top: 16px;

    .rolling-text-info-item {
        flex: 1;

        &:not(:first-child) {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
}