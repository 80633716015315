.ant-card {
    .ant-card-head {
        font-weight: normal;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
        color: #fff;

        .ant-card-head-title {
            line-height: 2;
            overflow: visible;
        }
    }

    .ant-card-body {
        line-height: 1.3;
    }

    &.green {
        border: 1px solid rgb(39, 73, 22);
        background: #111b0f;
        color: rgb(106, 190, 57);

        .ant-card-head {
            background: rgb(106, 190, 57);
            border-bottom: 1px solid rgb(39, 73, 22);
        }
    }

    &.red {
        border: 1px solid #58181c;
        background: rgba(42, 18, 21, 0.8);
        color: #e84749;

        .ant-card-head {
            background: #e84749;
            border-bottom: 1px solid #58181c;
        }
    }

    &.blue {
        border: 1px solid #15325b;
        background: rgba(17, 26, 44, 0.8);
        color: #3c89e8;

        .ant-card-head {
            background: #3c89e8;
            border-bottom: 1px solid #15325b;
        }
    }

    &.geekblue {
        border: 1px solid #1c2755;
        background: rgba(19, 22, 41, 0.8);
        color: #5273e0;

        .ant-card-head {
            background: #5273e0;
            border-bottom: 1px solid #1c2755;
        }
    }

    &.volcano {
        border: 1px solid #592716;
        background: rgba(43, 22, 17, 0.8);
        color: #e87040;

        .ant-card-head {
            background: #e87040;
            border-bottom: 1px solid #592716;
        }
    }

    &.purple {
        border: 1px solid #301c4d;
        background: rgba(26, 19, 37, 0.8);
        color: #854eca;

        .ant-card-head {
            background: #854eca;
            border-bottom: 1px solid #301c4d;
        }
    }

    &.magenta {
        border: 1px solid #551c3b;
        background: rgba(41, 19, 33, 0.8);
        color: #e0529c;

        .ant-card-head {
            background: #e0529c;
            border-bottom: 1px solid #551c3b;
        }
    }

    &.cyan {
        border: 1px solid #144848;
        background: rgba(17, 33, 35, 0.8);
        color: #33bcb7;

        .ant-card-head {
            background: #33bcb7;
            border-bottom: 1px solid #144848;
        }
    }

    &.orange {
        border: 1px solid #77350a;
        background: rgba(40, 27, 3, 0.8);
        color: #ff8c00;

        .ant-card-head {
            background: #ff8c00;
            border-bottom: 1px solid #79370c;
        }
    }

    &.teal {
        border: 1px solid #67134f;
        background: rgba(38, 5, 29, 0.8);
        color: #ba2490;

        .ant-card-head {
            background: #ba2490;
            border-bottom: 1px solid #6b1452;
        }
    }

    &.brown {
        border: 1px solid #5c2d0b;
        background: rgba(39, 20, 6, 0.8);
        color: #cd853f;

        .ant-card-head {
            background: #cd853f;
            border-bottom: 1px solid #8b4513;
        }
    }

    &.navy {
        border: 1px solid #023970;
        background: rgba(2, 21, 41, 0.8);
        color: #1e90ff;

        .ant-card-head {
            background: #1e90ff;
            border-bottom: 1px solid #001f3f;
        }
    }

}

.summary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-bottom: 24px;

    @media screen and (min-width: $screen-md) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.list-card-container {
    display: grid;
    gap: 12px;
    margin-bottom: 24px;

    @media screen and (min-width: $screen-md) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.list-card {
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #1d1f22;

    .list-card-head {
        background: #151819;
        padding: 12px;
        color: rgba(255, 255, 255, 0.8);
        border-bottom: 1px solid #1d1f22;
    }

    .list-card-body {
        background: #0d0f10;
        color: #fff;
        height: 240px;
        max-height: 300px;
        overflow: auto;
    }
}

.list-card-item {
    display: flex;
    justify-content: space-between;
    padding: 11px;
    line-height: 1;

    &:not(:last-child) {
        border-bottom: 1px solid #1d1f22;
    }

    .list-card-item-bank {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.75);
    }

    .list-card-item-amount {
        display: flex;
        align-items: center;
        color: var(--color-primary);
    }
}

.table-card {
    .ant-pagination {
        margin: 0 !important;
        padding: 12px 0;
        background: #0d0f10;
        border-radius: 0 0 16px 16px;
    }

    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td {
        line-height: 1.4;
    }
}