.ant-btn-primary {
    background: $gradient-primary !important;
    box-shadow: 0 0 0 0 hsla(var(--color-primary-hsl), 0);
    border: 0 !important;
    will-change: transform, box-shadow;

    span {
        color: var(--color-text-primary-bg) !important;
        text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
    }

    div {
        small {
            color: var(--color-text-primary-bg) !important
        }
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 25px 20px -20px;
    }

    &:disabled {
        cursor: not-allowed;
        border-color: #434343 !important;
        background: #303030 !important;
        box-shadow: none;

        span {
            color: rgba(255, 255, 255, 0.5) !important;
        }

        &:hover {
            transform: scale(1);
            box-shadow: 0 0 0 0 transparent;
        }
    }
}

.ant-btn-tertiary {
    background: $gradient-tertiary !important;
    box-shadow: 0 0 0 0 hsla(var(--color-tertiary-hsl), 0);
    border: 0 !important;
    will-change: transform, box-shadow;

    span {
        color: var(--color-text-tertiary-bg) !important;
        text-shadow: 1px 2px 4px var(--color-text-shadow-tertiary-bg);
    }

    div {
        small {
            color: var(--color-text-tertiary-bg) !important
        }
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: hsla(var(--color-tertiary-hsl), 0.5) 0 25px 20px -20px;
    }
}

.ant-btn-secondary {
    background: $gradient-secondary !important;
    box-shadow: 0 0 0 0 hsla(var(--color-secondary-hsl), 0);
    border: 0 !important;
    will-change: transform, box-shadow;

    span {
        color: var(--color-text-secondary-bg) !important;
        text-shadow: 1px 2px 4px var(--color-text-secondary-tertiary-bg);
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: hsla(var(--color-secondary-hsl), 0.5) 0 25px 20px -20px;
    }
}

.ant-btn-secondary2 {
    background: $gradient-dark !important;
    box-shadow: 0 0 0 0 hsla(var(--color-secondary-hsl), 0);
    border: 0 !important;
    will-change: transform, box-shadow;

    span {
        color: var(--color-text-secondary) !important;
        text-shadow: 1px 2px 4px var(--color-text-secondary-tertiary-bg);
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: hsla(var(--color-secondary-hsl), 0.5) 0 25px 20px -20px;
    }
}

.ant-btn-dark {
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 0 hsla(var(--color-primary-hsl), 0), rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border: 0 !important;
    will-change: transform, box-shadow;

    span {
        color: $color-white !important;
    }

    &:hover {
        transform: scale(1.015);
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 25px 20px -20px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    }
}

.ant-btn-light {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 0 0 hsla(var(--color-secondary-hsl), 0);
    border: 0 !important;

    span {
        color: $color-black;
    }

    &:hover {
        transform: scale(1.05);
        background: rgba(255, 255, 255, 0.9);
    }
}

.ant-btn-line {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        margin-top: 2px;
    }

    &:hover {
        color: #06C755;
        border-color: #06C755;
    }
}

.paste-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    height: calc(100% - 2px) !important;
    border-left: 1px solid #434343;
    border-radius: 0 $border-radius-card $border-radius-card 0 !important;
    background-color: #0d0f10 !important;
    opacity: 1 !important;
}

.ant-divider {
    border-color: rgba(255, 255, 255, 0.1);
}

.ant-divider-horizontal {
    margin: 16px 0;
}

.ant-btn.ant-btn-loading {
    display: flex;
    justify-content: center;
    line-height: 1.1;
}

.ant-btn-success {
    color: #00d155 !important;
    background-color: rgba(34, 115, 67, 0.5) !important;
    box-shadow: rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border: 0 !important;
}

.ant-float-btn {
    inset-block-end: 80px;

    @media (min-width: $screen-lg) {
        inset-block-end: 48px;
    }
}

.ant-float-btn-group {
    inset-block-end: 150px;

    @media (min-width: $screen-lg) {
        inset-block-end: 120px;
    }
}

.ant-float-btn-default .ant-float-btn-body {
    background-color: $gradient-primary !important;
}

.ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    font-size: 28px;
}

.ant-btn {
    &.ant-btn-sm {
        padding: 6.5px 12px;
        font-size: 22px;

        &:hover {
            box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 12px 20px -10px !important;
        }
    }
}

.custom-icon {
    .ant-btn-icon {
        font-size: 20px;
    }
}