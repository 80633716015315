@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;600&display=swap");
@import './../fontawesome-pro/css/all.css';

@font-face {
    font-family: "DB HelvethaicaX";
    src: url("../../webfonts/DBHelvethaicaX-55Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "DB HelvethaicaX";
    src: url("../../webfonts/DBHelvethaicaX-65Med.ttf");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}