
.contact-card {
	display: flex;
	align-items: center;
	padding: 16px;
	background: $gradient-light;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
	border-radius: 16px;

	.contact-icon {
		img {
			width: 72px;
		}
	}

	.contact-text {
		padding-left: 16px;

		@media screen and (min-width: $screen-xl) {
			a {
				width: 300px;
			}
		}

		h6 {
			margin-bottom: 0;
			opacity: 0.65;
		}

		a {
			display: block;
			width: 200px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
		}
	}
}
