.web-container {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    @media (min-width: $screen-lg) {
        max-width: 960px;
    }

    @media (min-width: $screen-xl) {
        max-width: 1140px;
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
        max-width: 720px;
    }
}

.redo-button {
    will-change: transform;
    transition: all 0.25s ease;

    &:hover {
        transform: rotate(360deg);
    }
}
