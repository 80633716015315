.install-app.ant-alert-with-description {
    padding: 10px 12px !important;
}

.install-app {
    .ant-alert-message {
        font-size: 24px;
        margin-bottom: 2px
    }

    .ant-alert-description {
        font-size: 22px;
    }
}

.ant-alert {
    font-size: 14px;

    .ant-alert-message {
        font-size: 22px;
        line-height: 1.15;
        margin-bottom: 0;
    }
}