.provider-card {
    position: relative;
    display: block;
    cursor: pointer;

    &:hover {
        .provider-card-img {
            // background: darken($color-primary, 25%);
            box-shadow: hsla(var(--color-primary-hsl), 0.25) 0 12px 32px -16px;

            &::before {
                background: hsla(var(--color-primary-hsl), 0.2);
            }
        }

        .provider-card-character {
            transform: translateX(-50%) scale(1.05);
        }
    }
}

.provider-card-img {
    position: relative;
    display: block;
    background: #333;
    border-radius: 20px;
    box-shadow: rgba($color-black, 1) 0 12px 32px -16px;
    transition: all 0.25s ease-in-out;
    will-change: background, box-shadow;

    &::before {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        z-index: -1;
        background: rgba($color-line, 0.4);
        border-radius: 20px;
        transition: background 0.25s ease-in-out;
        will-change: background;
    }
}

.provider-card-bg {
    position: relative;
    padding-top: 75%;
    overflow: hidden;
    border-radius: 20px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 110px;
        filter: grayscale(100%);
        mix-blend-mode: multiply;

        @media (min-width: $screen-md) {
            height: 100%;
        }
    }
}

.provider-card-character {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 115px;
    transform: translateX(-50%);
    transition: transform 0.25s ease;
    will-change: transform;

    @media (min-width: $screen-md) {
        height: 115%;
    }
}

.provider-card-logo {
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 1s;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 1px;
        height: 1px;
        border-radius: 50%;
        box-shadow: rgba($color-black, 0.75) 0 0 30px 40px;
        transform: translate(-50%,-50%);
    }

    img {
        width: auto;
        height: 48px;

        @media (min-width: $screen-md) {
            height: 64px;
        }

        @media (min-width: $screen-xl) {
            height: 84px;
        }
    }
}