.range-picker-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #1d1f22;
    overflow: hidden;

    @media screen and (min-width: $screen-md) {
        flex-direction: row;
    }
}

.range-picker {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &:first-child {
        border-bottom: 1px solid #1d1f22;
    }

    .range-picker-label {
        grid-column: span 2;
        color: rgba(255, 255, 255, 0.8);
        background: #151819;
        padding: 8px;
        text-align: center;
        border-bottom: 1px solid #1d1f22;
    }

    .range-picker-data {
        border-right: 1px solid #1d1f22;
    }

    .ant-picker {
        width: 100%;
        border: 0;
        border-radius: 0;

        &.ant-picker-focused {
            box-shadow: none;

        }
    }

    @media screen and (min-width: $screen-md) {
        display: flex;

        &:first-child {
            border-bottom: 0;
        }

        .range-picker-label {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            padding: 0 12px;
        }

        .range-picker-data,
        .range-picker-time {
            flex: auto;
        }
    }
}

.filter-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-bottom: 24px;

    .ant-segmented {
        border: 1px solid #1d1f22;

        .ant-segmented-item {
            flex: auto;
            border-radius: 11px !important;
        }
    }

    @media screen and (min-width: $screen-md) {
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;
    }
}

.ant-picker-dropdown .ant-picker-decade-panel,
.ant-picker-dropdown .ant-picker-year-panel,
.ant-picker-dropdown .ant-picker-quarter-panel,
.ant-picker-dropdown .ant-picker-month-panel,
.ant-picker-dropdown .ant-picker-week-panel,
.ant-picker-dropdown .ant-picker-date-panel,
.ant-picker-dropdown .ant-picker-time-panel {
    width: 360px;
}


.ant-picker-content {
    width: auto;
}

.ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: var(--color-primary);
}