.section-content-container {
    padding: 20px 16px;
    margin: 24px 0 0;
    background: rgba(0, 0, 0, 0.75);
    // backdrop-filter: blur(50px);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
    border-radius: 12px;
    overflow: hidden;
    transition: height 0.3s ease-out;

    @media screen and (min-width: $screen-lg) {
        padding: 24px;
    }

    .section-content-body {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-out;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: rgba(255, 255, 255, 0.075);
            z-index: 1;
        }

        &.show {
            height: auto;
        }

        h1, h2, h3, h4, h5, p, span {
            color: $color-white;
            margin: 0;
        }

        img {
            max-width: 100%;
        }

        .ql-align-center {
            text-align: center;
        }
        .ql-align-right {
            text-align: right;
        }
        .ql-align-left {
            text-align: left;
        }
        .ql-align-justify {
            text-align: justify;
        }

    }

    .section-content-btn {
        width: 100%;
        color: $color-primary;
        cursor: pointer;
        text-align: center;

        &.add-padding {
            padding-top: 16px;

            @media screen and (min-width: $screen-lg) {
                padding-top: 24px;
            }
        }
    }
}