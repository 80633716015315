.layout-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    height: auto;
    margin-bottom: 12px;
    min-height: 56px;
    line-height: 1;

    @media (min-width: $screen-lg) {
        position: sticky;
        top: 12px;
        min-height: auto;
        max-width: 1140px;
        box-sizing: border-box;
        margin: 12px auto;
    }
}

.topbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2px 12px;
    // background-color: #101010;
    background: $gradient-primary-top;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.075);
    box-sizing: border-box;

    &.topbar-home {
        @media (max-width: $screen-lg) {
            .ant-btn {
                padding: 8px;
                height: 44px;
                font-size: 22px;
                border-radius: 12px;
            }
        }
    }

    &.topbar-hidden {
        display: none;

        @media (min-width: $screen-lg) {
            display: flex;
        }
    }

    &.topbar-dark {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 0;
        }
    }

    @media (min-width: $screen-lg) {
        background-color: rgba(0, 0, 0, 0.95);
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
        border-radius: 16px;
        // background-color: rgba(0, 0, 0, 0.7);
        // backdrop-filter: blur(20px);
        border-bottom: 0;
        padding: 12px 32px;

        &.topbar-home {
            padding: 12px 16px;
        }

        &.topbar-dark {
            &::before {
                border-radius: 16px;
            }
        }
    }
}

.topbar-logo {
    display: flex;
    align-items: center;

    @media (min-width: $screen-lg) {
        display: none;
    }

    .ant-avatar {
        max-width: 125px;
        height: 48px;
        max-height: 48px;
    }

    .prefix-name {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-weight: bold;
    }
}

.topbar-welcome {
    position: relative;
    display: none;

    .ant-typography {
        color: var(--color-text-primary-bg);
        text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
    }

    @media (min-width: $screen-lg) {
        display: flex;
        align-items: center;
    }
}

.topbar-short-des {
    font-size: 20px;
    margin-left: 16px;

    @media (min-width: $screen-lg) {
        display: none;
    }
}

.topbar-member {
    display: flex;
    align-items: center;

    .topbar-profile {
        display: flex;
        align-items: center;
    }

    .topbar-profile-rank {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;

        img {
            width: 48px;
        }
    }

    .topbar-info {
        padding: 0 0 0 4px;
        font-size: 20px;

        .anticon {
            color: $color-primary;
            font-size: 16px;
            opacity: 0.85;
            will-change: opacity;
            transition: opacity 0.25s ease;

            &:hover {
                opacity: 1;
            }
        }

        @media (min-width: $screen-lg) {
            padding: 0 8px;
            font-size: 24px;
        }
    }

    button {
        display: none;

        @media (min-width: $screen-lg) {
            display: block;
        }
    }
}

.topbar-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 56px;
    // background-color: #101010;
    background: $gradient-primary-top;
    padding: 0 12px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.075);
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &.no-border {
        border-bottom: 0
    }

    @media (min-width: $screen-lg) {
        display: none;
    }

    .topbar-mobile-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .ant-typography {
            color: var(--color-text-primary-bg);
            text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
        }
    }

    .anticon-left,
    .material-symbols-rounded {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
    }
}

.topbar-balance {
    display: flex;
    align-items: center;
    justify-content: center;

    .topbar-balance-number {
        line-height: 1;

        .ant-typography {
            font-size: 22px;
        }
    }

    @media (min-width: $screen-lg) {
        padding-right: 16px;
        margin-right: 16px;

        .topbar-balance-number {
            margin-right: 16px;
        }
    }
}

.topbar-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    height: 48px;
    color: $color-black;
    font-weight: 700;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.075);
    margin-right: 16px;
    cursor: pointer;

    img {
        max-height: 32px;
    }
}

.topbar-widget {
    position: relative;
    display: flex;
    align-items: center;

    .ant-btn-secondary {
        display: none;

        @media (min-width: $screen-lg) {
            display: block;
            border-radius: 16px;
        }
    }

}

.balance-widget {
    display: flex;
    align-items: center;
    border-radius: 12px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
    height: 40px;
    margin-left: 12px;
    overflow: hidden;

    .balance-widget-body {
        display: flex;
        align-items: center;
        background: $gradient-dark;
        padding: 0 12px;
        height: 100%;
    }

    .balance-widget-icon {
        width: 20px;
    }

    .balance-widget-number {
        margin-left: 8px;
    }

    .balance-widget-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gradient-secondary;
        color: var(--color-text-secondary-bg);
        height: 100%;
        width: 36px;

        .anticon,
        .anticon svg {
            color: var(--color-text-secondary-bg) !important;
        }
    }

    @media (min-width: $screen-lg) {
        height: 48px;
        border-radius: 16px;

        .balance-widget-button {
            background: $gradient-tertiary;
            color: var(--color-text-tertiary-bg) !important;
            width: 40px;

            .anticon,
            .anticon svg {
                color: var(--color-text-tertiary-bg) !important;
            }
        }
    }
}

a.balance-widget {
    color: var(--color-text-secondary-bg);

    @media (min-width: $screen-lg) {
        color: var(--color-text-tertiary-bg) !important;
    }
}

.menu-widget {
    position: relative;
    display: block;

    .menu-widget-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gradient-dark;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
        margin-left: 12px;
        cursor: pointer;

        @media (min-width: $screen-lg) {
            width: 48px;
            height: 48px;
            border-radius: 16px;
        }
    }

}

.menu-widget-content {
    position: absolute;
    top: 44px;
    right: 0;
    // background-color: #101010;
    background: $gradient-primary-top;
    width: 280px;
    border-radius: 16px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075), 0 4px 8px rgba(0, 0, 0, 0.5);
    padding: 12px;
    z-index: 10;

    .box-content {
        background: $gradient-light-soft;
        border-radius: 12px;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    .ant-btn-widget {
        height: 40px;
        padding: 0;
    }

    @media (min-width: $screen-lg) {
        top: 52px;
    }
}

.ant-drawer {
    z-index: 10000;

    .drawer-profile {
        background: #101010;

        .ant-drawer-header {
            padding: 10px 12px 4px;
            border: 0;

            .ant-drawer-header-title {
                .ant-drawer-close {
                    margin-left: auto;
                    margin-right: 0;
                    font-size: 24px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }
        }

        .ant-drawer-body {
            display: flex;
            flex-direction: column;
            padding: 0 16px 24px;

            .box-content {
                background: $gradient-light-soft;
                border-radius: 16px;
                box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
                padding: 8px 0 8px 8px;

                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }

            .menu-mobile-wrapper {
                padding: 0 16px;
                gap: 12px 24px;
                display: grid !important;

                @media (min-width: $screen-md) {
                    grid-template-columns: repeat(4, 1fr);
                }
            }

            .menu-mobile-icon {
                border-radius: 16px;
            }
        }
    }
}


.topbar-install-app {
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;

    .topbar-main {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .topbar-balance-number {
            line-height: 1;

            .ant-typography {
                font-size: 22px;
            }
        }

        @media (min-width: $screen-lg) {
            padding-right: 16px;
            margin-right: 16px;

            .topbar-balance-number {
                margin-right: 16px;
            }
        }
    }

    .topbar-profile {
        display: flex;
        align-items: center;
    }

    .topbar-image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;

        img {
            width: 48px;
            border-radius: 10%;
        }
    }

    .topbar-info {
        padding: 0 0 0 4px;
        font-size: 20px;

        .anticon {
            color: $color-primary;
            font-size: 16px;
            opacity: 0.85;
            will-change: opacity;
            transition: opacity 0.25s ease;

            &:hover {
                opacity: 1;
            }
        }

        @media (min-width: $screen-lg) {
            padding: 0 8px;
            font-size: 24px;
        }
    }
}

.topbar-game {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 48px;
    background: $gradient-primary-top;
    padding: 0 12px;
    display: flex;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: $color-white;

    .avatar-logo,
    .prefix-name {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 96px;
        height: 48px;

        img {
            object-position: center;
        }
    }

    @media (min-width: $screen-md) {
        height: 56px;

        .avatar-logo,
        .prefix-name {
            width: 130px;
            height: 56px;
        }
    }
}