@keyframes arrow-movement {
    0% {
        opacity: 0;
        transform: translateY(0) scale(0.8);
    }

    60% {
        opacity: 1;
        transform: translateY(8px) scale(1.1);
    }

    100% {
        opacity: 0;
        transform: translateY(12px) scale(0.9);
    }
}

.bank-card {
    display: flex;
    padding: 12px;
    background: $gradient-light;
    border-radius: $border-radius-card;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;

    .bank-card-logo {
        margin-bottom: 0;
        margin-right: 16px;
    }

    @media (min-width: $screen-lg) {
        padding: 16px;
    }
}

.bank-deposit-card {
    display: flex;
    align-items: center;
    padding: 12px;
    background: $gradient-light;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.075);
    border-radius: $border-radius-card;
    overflow: hidden;

    &.bank-form {
        background: $gradient-light-reverse;
    }

    &.bank-to {
        border: 1px solid $color-primary-20;
        animation: pulseTopOne 2s ease-in-out infinite;
    }

    @media (min-width: $screen-lg) {
        padding: 24px;
    }
}

.bank-deposit-arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -16px auto -8px;
    font-size: 24px;
    color: $color-white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: #111;
    box-shadow: rgba(0, 0, 0, 0.7) 0 0 16px;
    border: 1px solid rgba($color-white, 0.15);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $gradient-light;
        border-radius: 50%;
        z-index: 0;
    }

    .arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 12px;
        margin-left: -5px;
        margin-top: -8px;
        transform: translate(-50%, -50%);
        transform-origin: 50% -50%;
        opacity: 0;
        z-index: 1;

        &.arrow-first {
            animation: arrow-movement 2s ease-in-out infinite;
        }

        &.arrow-second {
            animation: arrow-movement 2s 1s ease-in-out infinite;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            display: block;
            width: 10px;
            height: 1px;
        }

        &:before {
            transform: rotate(45deg) translateX(-23%);
            transform-origin: top left;
        }

        &:after {
            transform: rotate(-45deg) translateX(23%);
            transform-origin: top right;
        }
    }
}

.bank-card-logo {
    position: relative;
    display: flex;
    border-radius: 16px;
    margin-right: 12px;
    width: 80px;
    height: 80px;
    overflow: hidden;

    @media (max-width: 390px) {
        width: 60px;
        height: 60px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba($color-black, 0) 20%, rgba($color-black, 1) 100%);
        box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 0.5px inset;
        border-radius: 16px;
        z-index: 2;
        mix-blend-mode: soft-light;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(315deg, rgba($color-white, 0) 20%, rgba($color-white, 0.5) 100%);
        border-radius: 16px;
        z-index: 2;
        mix-blend-mode: soft-light;
    }

    img {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.bank-card-info {
    line-height: 1;

    small {
        color: rgba($color-white, 0.7);
    }

    button {
        padding: 0;
        margin-top: 16px;
        font-size: 20px;
        height: auto;
    }

    .bank-deposite-account {
        color: $color-white;
        font-size: 30px;
        font-weight: bold;

        @media (max-width: 390px) {
            font-size: 26px;
        }
    }
}


.bank-card-logo-mini {
    position: relative;
    display: flex;
    border-radius: 16px;
    margin-right: 6px;
    width: 60px;
    height: 60px;
    overflow: hidden;

    @media (max-width: 390px) {
        width: 40px;
        height: 40px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba($color-black, 0) 20%, rgba($color-black, 1) 100%);
        box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 0.5px inset;
        border-radius: 16px;
        z-index: 2;
        mix-blend-mode: soft-light;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(315deg, rgba($color-white, 0) 20%, rgba($color-white, 0.5) 100%);
        border-radius: 16px;
        z-index: 2;
        mix-blend-mode: soft-light;
    }

    img {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}