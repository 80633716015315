.ant-upload-drag-custom {
    div.ant-upload {
        width: 100% !important;
        margin-bottom: 0 !important;
        margin-right: 0 !important;
    }

    .ant-upload-list-item-container {
        margin-bottom: 0 !important;
        margin-right: 0 !important;
    }

    span.ant-upload {
        flex-direction: column;
    }

    .ant-upload {
        background-color: rgba(0, 0, 0, 0.1) !important;
        border-radius: 12px;
    }

    .ant-tooltip {
        display: none;
    }

    .ant-upload-list {
        display: flex;
        justify-content: center;

        .ant-upload-list-picture-card-container {
            width: 100%;
            height: 240px;

            .ant-upload-list-item {
                overflow: hidden;
                padding: 0;

                .ant-upload-list-item-actions {
                    .anticon {
                        width: 32px;
                        font-size: 32px;
                    }
                }

                &:hover {
                    .ant-upload-list-item-info {
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}