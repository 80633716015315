.promotion-wallet-container {
    display: none;

    @media (min-width: $screen-md) {
        display: block;
    }

    .ant-ribbon-wrapper {
        height: 100%;
    }
}

.promotion-card {
    border-radius: $border-radius-card;
    border-color: transparent;
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.25), inset 0 0 0 1px rgba($color-white, 0.075);
    background: transparent;
    display: flex;
    flex-direction: column;
    height: 100%;

    .promotion-actions {
        margin-top: auto;

        @media (min-width: $screen-xl) {
            button {
                min-width: 140px;
            }
        }
    }

    .ant-card-cover {
        margin: 0;
        width: 100%;
        position: relative;

        img {
            display: block;
            object-fit: cover;
            border-radius: 20px 20px 0 0;
        }
    }

    .ant-card-body {
        border-top: 1px solid #303030;
        background: $gradient-light, rgba(0, 0, 0, 0.85);
        padding: 16px 12px;
        height: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-lg) {
            padding: 16px;
        }

        .ant-card-meta {
            margin-bottom: 16px;
        }

        .ant-card-meta-detail div {
            text-shadow: 0 1px 4px rgba($color-black, 0.5);
        }
    }

    .ant-card-meta-title {
        color: $color-primary;
        white-space: normal;
    }

    &.bg-grey {
        .ant-card-body {
            border: 0;
            background: #202020
        }
    }
}

.promotion-actions {
    display: flex;

    .ant-radio-wrapper {
        flex: auto;
        margin-right: 16px;
    }
}

.ant-flex-align-center {
    align-items: center;
}

.wallet-other-card-inner {
    align-items: center !important;
}