.notice-card {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    background: $gradient-light;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.075);
    border-radius: $border-radius-card;
    margin-bottom: 16px;
    overflow: hidden;

    &.warning {
        background: $gradient-warning;
        box-shadow: inset 0 0 0 1px rgba($color-warning, 0.25);
    }

    &.danger {
        background: $gradient-danger;
        box-shadow: inset 0 0 0 1px rgba($color-danger, 0.25);
    }

    @media (min-width: $screen-md) {
        padding: 20px 24px;
    }

    i {
        font-size: 20px;
    }

    .notice-card-icon {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 56px;
        height: 56px;
        animation: dance 5s infinite;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .notice-card-text {
        padding-left: 56px;
        font-size: 22px;

        @media (min-width: $screen-md) {
            font-size: 24px;
        }
    }
}