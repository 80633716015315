.tag-default {
    font-size: 22px;
    background: $gradient-lighter;
    color: $color-white;
    padding: 6px 12px !important;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
}

.tag-default-other {
    font-size: 22px;
    background: $gradient-lighter;
    color: $color-white;
    padding: 6px 10px !important;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
}

.tag-status {
    font-size: 22px;
    padding: 6px 12px !important;
    border-radius: 12px;

    &.--success {
        color: $color-success;
        background: $gradient-success;
        box-shadow: 0 4px 16px -8px rgba($color-success, 0.4), inset 0 0 0 1px rgba($color-success, 0.25);
    }

    &.--warning {
        color: $color-warning;
        background: $gradient-warning;
        box-shadow: 0 4px 16px -8px rgba($color-warning, 0.4), inset 0 0 0 1px rgba($color-warning, 0.25);
    }

    &.--danger {
        color: $color-danger;
        background: $gradient-danger;
        box-shadow: 0 4px 16px -8px rgba($color-danger, 0.4), inset 0 0 0 1px rgba($color-danger, 0.25);
    }
}

.tag-status-other {
    font-size: 22px;
    padding: 6px 6px !important;
    border-radius: 12px;

    &.--success {
        color: $color-success;
        background: $gradient-success;
        box-shadow: 0 4px 16px -8px rgba($color-success, 0.4), inset 0 0 0 1px rgba($color-success, 0.25);
    }

    &.--warning {
        color: $color-warning;
        background: $gradient-warning;
        box-shadow: 0 4px 16px -8px rgba($color-warning, 0.4), inset 0 0 0 1px rgba($color-warning, 0.25);
    }

    &.--danger {
        color: $color-danger;
        background: $gradient-danger;
        box-shadow: 0 4px 16px -8px rgba($color-danger, 0.4), inset 0 0 0 1px rgba($color-danger, 0.25);
    }
}