.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99999;
    background: #101010;

    .loader-body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        overflow: hidden;
    }
}

.loader {
    position: relative;
    cursor: pointer;
    padding: 0.35em 1em;
    background: none;
    perspective: 2em;
    margin-top: -10%;

    &.loader-text {
        color: #fff;
        border: 0.15em solid var(--color-primary);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.7;
        filter: blur(2em);
        transform: translateY(85%) rotateX(120deg) scale(1, 0.2);
        background: hsl(0, 0%, 98%);
        pointer-events: none;
        animation: lighting 5s infinite linear;
    }

    .logo {
        perspective: 100px;
        animation: rotateY 5s linear infinite;
    }
}

.glowing-btn {
    position: relative;
    color: rgba(#fff, 0.9);
    cursor: pointer;
    padding: 0.35em 1em;
    border: 0.075em solid rgba(#fff, 0.9);;
    border-radius: 0.45em;
    background: none;
    font-family: "Raleway", sans-serif;
    font-size: 1em;
    letter-spacing: 0.5em;
    animation: rotateY 3s linear infinite;
    filter: blur(0.1px);
    box-shadow: inset 0px 0px 0.5em 0px var(--color-primary, #fff),
        0px 0px 0.5em 0px var(--color-primary, #fff);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -70px;
        right: -70px;
        border-bottom: 30px solid var(--color-primary, #fff);
        border-left: 100px solid transparent;
        border-right: 100px solid transparent;
        height: 0;
        transform: translateY(90px);
        filter: blur(20px);
        opacity: 0.7;
        animation: lighting-2 3s infinite linear;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        background-color: var(--color-primary, #fff);
        box-shadow: 0 0 2em 0.2em var(--color-primary, #fff);
        transition: opacity 100ms linear;
    }
}

.glowing-txt {
    float: left;
    margin-right: -0.4em;
    text-shadow: 0 0 5px var(--color-primary, #fff), 0 0 10px var(--color-primary, #fff), 0 0 15px var(--color-primary, #fff);
    animation: text-flicker 3s linear infinite;
    font-weight: lighter;
}

@keyframes lighting-2 {
    0% {
        transform: translateX(-10%) translateY(200%) rotateX(100deg) scale(1, 0.5);
    }

    50% {
        transform: translateX(10%) translateY(200%) rotateX(100deg) scale(1, 0.5);
    }

    100% {
        transform: translateX(-10%) translateY(200%) rotateX(100deg) scale(1, 0.5);
    }
}

@keyframes text-flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 1;
    }

    8% {
        opacity: 0.1;
    }

    9% {
        opacity: 1;
    }

    12% {
        opacity: 0.1;
    }

    20% {
        opacity: 1;
    }

    25% {
        opacity: 0.3;
    }

    30% {
        opacity: 1;
    }

    70% {
        opacity: 0.7;
    }

    72% {
        opacity: 0.2;
    }

    77% {
        opacity: 0.9;
    }

    100% {
        opacity: 0.9;
    }
}

@keyframes border-flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 1;
    }

    4% {
        opacity: 0.1;
    }

    8% {
        opacity: 1;
    }

    70% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}


@keyframes rotateY {
    0% {
        transform: rotateY(-11deg);
    }

    50% {
        transform: rotateY(11deg);
    }

    100% {
        transform: rotateY(-11deg);
    }
}

@keyframes lighting {
    0% {
        transform: translateX(-40%) translateY(85%) rotateX(120deg) scale(1, 0.2);
    }

    25% {
        transform: translateX(10%) translateY(90%) rotateX(115deg) scale(1, 0.21);
    }

    50% {
        transform: translateX(60%) translateY(85%) rotateX(120deg) scale(1, 0.2);
    }

    75% {
        transform: translateX(10%) translateY(90%) rotateX(115deg) scale(1, 0.21);
    }

    100% {
        transform: translateX(-40%)translateY(85%) rotateX(120deg) scale(1, 0.2);
    }
}

@keyframes lighting-2 {
    0% {
        transform: translateX(20px) translateY(90px);
    }

    25% {
        transform: translateX(0) translateY(90px);
    }

    50% {
        transform: translateX(-20px) translateY(90px);
    }

    75% {
        transform: translateX(0) translateY(90px);
    }

    100% {
        transform: translateX(20px) translateY(90px);
    }
}