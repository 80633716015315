.partner-card {
    position: relative;
    padding: 16px;
    background: $gradient-light, rgba(0, 0, 0, 0.85);
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border-radius: $border-radius-card;
    margin-bottom: 16px;

    &.primary-bg {
        background: $gradient-primary-transparent-linear, $gradient-light, rgba(0, 0, 0, 0.85);
    }

    .ant-picker-panels {
        display: none;
    }
}

.partner-commission {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .partner-commission-amount {
        line-height: 0.9;

        .partner-commission-number {
            font-size: 44px;
            font-weight: 700;
            color: $color-white;
        }
    }
}

.partner-calendar {
    .ant-picker-panels {
        .ant-picker-panel {
            &:not(:first-child) {
                display: none;
            }
        }
    }

    &.ant-picker-dropdown {
        z-index: 9999;

        .ant-picker-date-panel,
        .ant-picker-month-panel,
        .ant-picker-year-panel {
            width: 320px;

            .ant-picker-body {
                padding: 8px;
            }

            .ant-picker-content {
                table-layout: auto;
                width: auto;
            }
        }

        .ant-picker-header {
            height: 48px;

            button {
                visibility: visible !important;
                line-height: 48px;
            }
        }

        .ant-picker-cell {
            padding: 4px 0;

            &::before {
                height: 32px;
            }

            .ant-picker-cell-inner {
                height: 32px;
                line-height: 32px;
            }
        }

        .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
        .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
        .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
            background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.25) !important;
        }
    }
}

.partner-statistic {
    background: $gradient-light, rgba(0, 0, 0, 0.85);
    padding: 12px 16px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    line-height: 1;

    .partner-statistic-title {
        color: #b6b6b6;
        margin-bottom: 3px;
    }

    .partner-statistic-amount {
        font-size: 32px;
        font-weight: 700;
        color: $color-white;

        &.deposit {
            color: #17c5a2;
        }

        &.withdraw {
            color: #ff2c2c;
        }

        &.profit {
            color: #299ddc;
        }

        &.company {
            color: #ffc400;
        }

        &.net-profit {
            color: #e433ff;
        }

        &.commission {
            color: #ff33a1;
        }

        &.new-member {
            color: #ff4c10;
        }

        &.deposit-member {
            color: #a23eff;
        }
    }

    .partner-statistic-extra {
        color: #8a8a8a;
        font-size: 20px;
    }
}

.partner-tag {
    color: var(--color-text-primary-bg) !important;
    background: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

.partner-table-card {
    background-color: #0d0f10;
    border-radius: 12px;
    padding-bottom: 12px;

    .ant-table-wrapper .ant-table-pagination-right {
        justify-content: center;
    }

    .ant-empty-image {
        display: none;
    }

    .odd-row {
        background-color: #111516;
    }

    .odd-row, .even-row {
        .ant-table-cell-row-hover {
            background-color: #16191a !important;
        }
    }

    .ant-table-cell {
        min-width: 90px;
    }
}

.partner-search {
    margin-bottom: 16px;

    .ant-input-affix-wrapper {
        padding: 6.5px 12px;
    }

    .ant-input-group-addon {
        background-color: #0d0f10;
        border-radius: 10px;
        font-size: 20px;
    }

    .ant-input-search-button {
        height: 44px !important;
    }
}