.redeem-free-container {
    position: fixed;
    left: 8px;
    bottom: 84px;
    z-index: 9999;
    cursor: pointer;

    &.fade-out {
        animation: fadeOut 0.25s 1 normal both;
    }

    @media (min-width: $screen-lg) {
        left: 32px;
        bottom: 56px;
    }

    @media (min-width: $screen-xl) {
        bottom: 56px;
        left: calc(280px + 8px);
    }

    .redeem-free {
        animation: textZoom 2s 1s ease infinite;

        .gift-top {
            position: absolute;
            top: -6px;
            left: 1.5px;
            width: 48px;
            animation: shakeBoxTwo 1s 0.25s normal infinite;
        }

        .gift-bottom {
            width: 46px;
            margin: 0 auto;
            display: block;
            filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.75));
        }

        .redeem-free-label {
            position: relative;
            font-size: 16px;
            background: $gradient-secondary;
            color: var(--color-text-secondary-bg);
            text-shadow: 1px 1px 1px var(--color-text-shadow-secondary-b);
            padding: 0 4px;
            border-radius: 16px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.75);
            margin-top: 4px;
            overflow: hidden;
            animation: textZoom 2s ease infinite;

            &::before {
                position: absolute;
                top: -24px;
                left: 0;
                z-index: -2;
                width: 72px;
                height: 72px;
                background-color: transparent;
                background-image: linear-gradient(90deg,
                        hsla(var(--color-secondary-h),
                            var(--color-secondary-s),
                            calc(var(--color-secondary-l) + 40%), 0) 0%,
                        hsla(var(--color-secondary-h),
                            var(--color-secondary-s),
                            calc(var(--color-secondary-l) + 40%), 1) 50%,
                        hsla(var(--color-secondary-h),
                            var(--color-secondary-s),
                            calc(var(--color-secondary-l) + 40%), 0) 100%);
                background-repeat: no-repeat;
                transform-origin: center;
                -webkit-animation: rotate 2s linear infinite;
                animation: rotate 2s linear infinite;
                content: '';
            }

            &::after {
                position: absolute;
                top: 1px;
                right: 1px;
                bottom: 1px;
                left: 1px;
                background: $gradient-secondary;
                border-radius: $border-radius-card;
                content: '';
            }
        }

        @media (min-width: $screen-xl) {
            .gift-top {
                top: -10px;
                left: -1px;
                width: 56px;
            }

            .gift-bottom {
                width: 54px;
            }

            .redeem-free-label {
                font-size: 16px;
            }
        }
    }


    .redeem-free-close {
        position: absolute;
        top: -16px;
        right: -16px;
        color: $color-white;
        font-size: 16px;
        padding: 4px;
        z-index: 1;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

        @media (min-width: $screen-xl) {
            top: -24px;
            right: -24px;
            font-size: 20px;
        }
    }
}

@keyframes shakeBoxTwo {
    0% {
        transform: translate(0.5px, 0.5px) rotate(0deg);
    }

    10% {
        transform: translate(-0.5px, -1px) rotate(-1deg);
    }

    20% {
        transform: translate(-1.5px, 0) rotate(1deg);
    }

    30% {
        transform: translate(1.5px, 1px) rotate(0deg);
    }

    40% {
        transform: translate(0.5px, -0.5px) rotate(1deg);
    }

    50% {
        transform: translate(-0.5px, 1px) rotate(-1deg);
    }

    60% {
        transform: translate(-1.5px, 0.5px) rotate(0deg);
    }

    70% {
        transform: translate(1.5px, 0.5px) rotate(-1deg);
    }

    80% {
        transform: translate(-0.5px, -0.5px) rotate(1deg);
    }

    90% {
        transform: translate(0.5px, 1px) rotate(0deg);
    }

    100% {
        transform: translate(0.5px, -1px) rotate(-1deg);
    }
}

@keyframes textZoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}