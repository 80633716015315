// Snowflakes Config
$flakes: 88; // match flakes in mark up
$snowColor: #ffffff;

// Colors
$topGradient: #00A3EF;
$bottomGradient: #B7E7FC;
$textColor: #ffffff;

@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    -o-transform: $transforms;
    transform: $transforms;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} { @content; }
    @-moz-keyframes #{$name} { @content; }
    @-o-keyframes #{$name} { @content; }
    @keyframes #{$name} { @content; }
}

@mixin background-image($gradient1, $gradient2, $gradient3, $gradient4) {
    background-image: #{$gradient1}, #{$gradient2}, #{$gradient3}, #{$gradient4};
}

@mixin animation($str) {
    animation: $str;
    -webkit-animation: $str;
    -moz-animation: $str;
    -o-animation: $str;
}

// Let it snow
.snow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.flake {
    position: absolute;
    border-radius: 50%;
    @include transform(translateY(0) rotateX(0) rotateY(0));
    @include background-image(
        linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, $snowColor 50%, $snowColor 60%, rgba(255, 255, 255, 0) 60%),
        linear-gradient(90deg, rgba(255, 255, 255, 0) 30%, $snowColor 50%, $snowColor 60%, rgba(255, 255, 255, 0) 60%),
        linear-gradient(45deg, rgba(255, 255, 255, 0) 33%, $snowColor 53%, $snowColor 57%, rgba(255, 255, 255, 0) 65%),
        linear-gradient(135deg, rgba(255, 255, 255, 0) 33%, $snowColor 53%, $snowColor 57%, rgba(255, 255, 255, 0) 65%)
    );
}

@for $i from 1 through $flakes {
    $flakeSize: 5 + random(15);
    $flakeVertical: -700 + random(700);
    $flakeHorizontal: random(100);
    $flakeBlur: 2 + random(2);
    $flakeDelay: 15 + random(55);

    .flake:nth-child(#{$i}) {
        width: $flakeSize + px;
        height: $flakeSize + px;
        top: $flakeVertical + px;
        left: #{$flakeHorizontal + "%"};
        opacity: (50 + random(50)) * 0.01;
        filter: blur($flakeBlur + px);
        @include animation(#{$flakeDelay + s} flakes linear infinite);
    }
}

@keyframes flakes {
    100% {
        @include transform(translateY(1000px) rotateX(10 + random(40) + deg) rotateY(10 + random(40) + deg));
        opacity: 0;
    }
}
