.check-in-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    margin: auto;

    .check-in-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        padding: 12px;
        color: #dedede;
        background: linear-gradient(-45deg, rgba(#101010, 1), rgba(#303030, 1));
        box-shadow: inset 0 0 0 0.5px rgba(#fff, 0.1);
        border-radius: 12px;
        text-align: center;

        img {
            width: 24px;
            height: 24px;
            margin: 4px 0;
            filter: drop-shadow(0 3px 4px rgba(#ffdb32, 0.3));
        }

        .check-in-item-reward {
            color: #ffffff;
            font-size: 22px;
            font-weight: 700;
        }

        .check-in-checked {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            display: none;
        }

        &.final {
            position: relative;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            grid-column: 3 / 5;
            grid-row: 2 / 3;
            padding: 12px 16px;
            text-align: left;
            animation: pulseGold 1.5s infinite;
            background: transparent;
            overflow: hidden;
            z-index: 2;

            &::before {
                content: '';
                position: absolute;
                top: -130px;
                left: -100px;
                width: 400px;
                height: 400px;
                background-color: transparent;
                background-image: linear-gradient(90deg, rgba(255, 219, 50, 0) 0%, rgba(255, 219, 50, 1) 50%, rgba(255, 219, 50, 0) 100%);
                background-repeat: no-repeat;
                transform-origin: center;
                -webkit-animation: rotate 2s linear infinite;
                animation: rotate 2s linear infinite;
                z-index: -2;
            }

            &::after {
                content: '';
                position: absolute;
                top: 1px;
                right: 1px;
                bottom: 1px;
                left: 1px;
                background: linear-gradient(-45deg, rgba(#101010, 1), rgba(#303030, 1));
                border-radius: 12px;
                z-index: -1;
            }

            &.checked {
                background: linear-gradient(-45deg, rgba(#101010, 1), rgba(#303030, 1));
                animation: none;

                &::before {
                    display: none;
                }
            }

            .check-in-coin {
                width: 72px;
                height: 72px;
                margin: 0;
            }

            .check-in-item-reward {
                color: #ffffff;
                font-size: 28px;
                line-height: 1;
            }
        }

        &.pending {
            background: $gradient-primary-reverse;
            text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
            box-shadow: inset 0 0 0 1px rgba(#fff, 0.15), 0 4px 8px rgba(#000, 0.1);
        }

        &.checked {
            background: linear-gradient(-45deg, rgba(#101010, 0.65), rgba(#303030, 0.55));

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(-45deg, rgba(#000, 0.65), rgba(#000, 0.4));
                z-index: 1;
                border-radius: 12px;
            }

            .check-in-coin {
                filter: grayscale(100%);
            }

            .check-in-checked {
                display: block;
                filter: drop-shadow(0 3px 4px rgba(#2fd879, 0.3));
                z-index: 2;
            }

            >div {
                opacity: 0.5;
            }
        }

    }
}

.check-in-condition {
    font-size: 20px;
    color: rgba(#fff, 0.88);
    margin-top: 18px;

    u {
        color: #fff;
    }

    p {
        line-height: 1.05;
        margin-top: 0;
        margin-bottom: 6px;
    }

    .ant-typography {
        font-size: 20px;
    }
}