.invite-card {
    position: relative;
    display: none;
    width: 100%;
    padding: 28px 24px;
    background: $gradient-light;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
    border-radius: 20px;
    box-sizing: border-box;

    @media (min-width: $screen-xl) {
        display: block;
    }


    .invite-card-body {
        position: relative;
        z-index: 1;
    }
}

.affiliate-qrcode {
    background: $background-base;
    box-shadow: 0 8px 12px rgba($color-primary, 1);
    border: 1px solid #434343;
    border-radius: 16px;
    padding: 8px;
}

.invite-qrcode-container {
    position: relative;
    display: table;
    margin: 0 auto;
    margin-bottom: 16px;

    .invite-qrcode {
        position: relative;
        z-index: 3;
        padding: 12px 12px 4px;
        background: $background-base;
        box-shadow: 0 8px 12px rgba($color-primary, 1);
        border-radius: 16px;

        &::before {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $gradient-primary-shadow;
            transform: translate3d(0px, 20px, 0) scale(0.95);
            filter: blur(30px);
            opacity: 0.5;
            transition: opacity 0.3s;
            border-radius: 20px;
        }

        &::after {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: inherit;
            border-radius: inherit;
        }

    }
}