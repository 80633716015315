.bank-radio {
    background-color: $color-grey-2;
    border: 1px solid rgba($color-grey-1, 0.25);
    border-radius: 12px;
    padding: 12px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 1;
    transition: all 0.25s ease;

    small {
        color: $text-color-secondary;
    }

    &.ant-radio-wrapper-checked {
        border: 1px solid $color-primary !important;
    }

    .bank-radio-info {
        padding-left: 8px;
    }

    .ant-radio {
        top: 0;
    }

    &:hover {
        border: 1px solid $color-primary;
    }
}
